import React from "react";
import { Container, Row, Col, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { connect } from "react-redux";
import UserSidebar from "./UserDashboardSidebar";
import alertify from "alertifyjs";
import axios from "axios";
import { ENDPOINT } from "../constants";

class ChangePassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hasOtp: true,
            new_password: '',
            confirm_password: '',
            old_password: '',
            otpNumber: ''
        };
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handlePasswordSubmit = async (e) =>{
        e.preventDefault();
        var otp_number = document.getElementById('otpNumber');
        var new_password_el = document.getElementById('newPassword');
        var confirm_password_el = document.getElementById('confirmResetPassword');

        if(!/^[+0-9]+$/.test(this.state.otpNumber)){
            if(this.state.otpNumber === ""){
              alertify.notify('Otp can not be empty', 'custom', 2, function(){});
              otp_number.style.border = "1px solid #F98575";
              return;
            }else{
              alertify.notify('Otp should contain only numbers', 'custom', 2, function(){});
              otp_number.style.border = "1px solid #F98575";
              return;
            }
        }else{
            if(this.state.otpNumber.length !== 6){
              alertify.notify('Otp provided is too long/short', 'custom', 2, function(){});
              otp_number.style.border = "1px solid #F98575";
              return;
            }else{
                otp_number.style.border = "1px solid #ddd";
            }
        }

        if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{6,20})/.test(this.state.new_password)){
            if(this.state.password === ""){
                alertify.notify('Password cannot be empty', 'custom', 2, function(){});
                new_password_el.style.border = "1px solid #F98575";
                return;
            }else{
                alertify.notify('Password should only contain atleast Uppercase, lowercase, special characters and be 6 to 20 characters long', 'custom', 2, function(){});
                new_password_el.style.border = "1px solid #F98575";
                return;
            }
        }else{
            new_password_el.style.border = "1px solid #ddd";
        }

        if(this.state.new_password !== this.state.confirm_password){
            confirm_password_el.style.border = "1px solid #F98575";
            alertify.notify('Passwords do not match', 'custom', 2, function(){});
        }else{
            await this.setState({
                processing: true,
            });
            confirm_password_el.style.border = "1px solid #ddd";
            const res = await axios.put(`${ENDPOINT}/app/uaa/v1/change-request/password/${this.state.otpNumber}/${this.state.new_password}/otp`,{
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + this.props.Auth?.token
                },
                dataType: "json",
            });
            const { success } = res?.data;
            if(success){
                alertify.success("Account password changed successfully", 'custom', 2, function(){});
                await this.setState({
                    processing: false,
                    new_password: '',
                    confirm_password: '',
                    old_password: '',
                    otpNumber: ''
                });       
            } else{
                await this.setState({
                    modal: false,
                    processing: false,
                });
                alertify.notify("Password change failed", 'custom', 2, function(){});
            }
        }
    }   

    handleChange = (e) =>{
        if(e.target.id == 'otpNumber'){
            this.setState({
                otpNumber: e.target.value
            });
        }
        if(e.target.id == 'newPassword'){
            this.setState({
                new_password: e.target.value
            });
        }
        if(e.target.id == 'confirmResetPassword'){
            this.setState({
                confirm_password: e.target.value
            });
        }
    }

    handleMethodChange = async (e) =>{
        if(e.target.value === 'otp'){
            this.setState({
                hasOtp: true,
            });
        }else if(e.target.value === 'new'){
            this.setState({
                hasOtp: false,
                processing: true
            });
            if(this.props.Auth?.user?.phone !== undefined && this.props.Auth?.user?.phone !== null && this.props.Auth?.user?.phone !== ""){
                try{
                    const body = JSON.stringify({
                        phone: this.props.Auth?.user?.phone.replace(/ +/g, "")
                    }); 
                    const res = await this.requestOtp(body);
                    const { success } = res?.data;
                    if(success){
                        alertify.success("Request code sent, please check your primary contact for details", 'custom', 2, function(){});
                        await this.setState({
                            processing: false,
                            hasOtp: true,
                        });
                    } else{
                        alertify.notify("Request failed", 'custom', 2, function(){});
                    }
                }catch(err){

                }finally{
                    await this.setState({
                        processing: false
                    });
                }
            } else{
                try{
                    const body = JSON.stringify({
                        email: this.props.Auth?.user?.email
                    });
                    const res =  this.requestOtp(body);
                    const { success } = res?.data;
                    if(success){
                        alertify.success("Request code sent, please check your primary contact for details", 'custom', 2, function(){});
                        await this.setState({
                            hasOtp: true,
                        processing: false,
                        });
                    } else{
                        alertify.notify("Request failed", 'custom', 2, function(){});
                    }
                }catch(err){

                }finally{
                    await this.setState({
                        processing: false
                    });
                }
            }
        }
    }

    requestOtp = async (data) =>{
        return await axios.post(`${ENDPOINT}/app/uaa/v1/change-password`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.props.Auth?.token
          },
          dataType: "json"
        });
    }
    
    render(){
        return (
            this.state.processing ? 
                <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                    <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                        <Spinner animation="grow" variant='success' />
                    </div>
                </div>:
            <>
                <section id="content" className="page-single bg-grey with-sidebar">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                <Container>
                                    <Row>
                                        <Col md={12}>
                                            <div className="page-single-content sidebar-left">
                                                <Row>
                                                    <Col lg={3} md={12} className="mb-4"><UserSidebar/></Col>
                                                    <Col lg={9} md={12}>
                                                        <div className="content-main">
                                                            <div className="block-content-2">
                                                                <div className="block-title"><h3>Reset Password</h3></div>
                                                                <div className="booking-cost">
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <Row className="btn-setting">
                                                                                <Col xs={6} className="btn-setting-1">
                                                                                    <input type="radio" value="otp" style={{color: '#549A8B'}} onChange={this.handleMethodChange} checked={this.state.hasOtp === true} name="method" /> Has Otp 
                                                                                </Col>
                                                                                <Col xs={6} className="btn-setting-2">
                                                                                &nbsp;&nbsp;<input type="radio" value="new" style={{color: '#549A8B'}} onChange={this.handleMethodChange} checked={this.state.hasOtp === false} name="method" /> &nbsp;Request Otp
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <Form id="form-hotel-booking" onSubmit={this.handlePasswordSubmit}>
                                                                    <div className="form-content">
                                                                        {this.state.hasOtp ? <FormGroup>
                                                                            <div className="fields-row fields-2">
                                                                                <div className="box-field">
                                                                                    <Label for="otpNumber">  Otp Number </Label>
                                                                                    <Input
                                                                                        type="number"
                                                                                        name="otpNumber"
                                                                                        id="otpNumber"
                                                                                        className="form-control"
                                                                                        placeholder=""
                                                                                        value={this.state.otpNumber}
                                                                                        onChange={this.handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="box-field"></div>
                                                                            </div>
                                                                        </FormGroup> : ""}
                                                                        <FormGroup>
                                                                            <div className="fields-row fields-2">
                                                                                <div className="box-field">
                                                                                    <Label for="newPassword"> New Password </Label>
                                                                                    <Input
                                                                                        type="password"
                                                                                        name="newPassword"
                                                                                        id="newPassword"
                                                                                        className="form-control"
                                                                                        placeholder=""
                                                                                        value={this.state.new_password}
                                                                                        onChange={this.handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="box-field"></div>
                                                                            </div>
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <div className="fields-row fields-2">
                                                                                <div className="box-field">
                                                                                    <Label for="confirmResetPassword">Confirm Password</Label>
                                                                                    <Input
                                                                                        type="password"
                                                                                        name="confirmResetPassword"
                                                                                        id="confirmResetPassword"
                                                                                        className="form-control"
                                                                                        placeholder=""
                                                                                        value={this.state.confirm_password}
                                                                                        onChange={this.handleChange}
                                                                                    />
                                                                                </div>
                                                                                <div className="box-field"></div>
                                                                            </div>
                                                                        </FormGroup>                                                       
                                                                        <FormGroup>
                                                                            <Input
                                                                                type="submit"
                                                                                className="form-control"
                                                                                value="Update Password"
                                                                            />
                                                                        </FormGroup>
                                                                    </div>
                                                                </Form>
                                                            </div> 
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div> 
                                        </Col> 
                                    </Row> 
                                </Container> 
                            </div> 
                        </div> 
                    </div> 
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
      ...state
    }
}

export default connect(mapStateToProps, null)(ChangePassword);