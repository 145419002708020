import React, { useState, useEffect } from "react";
import { Container, Row, Spinner, Col} from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import { connect } from "react-redux";
import { updateWalletBalance } from "../actions/bookingActions";
import { redirectToRecentUrl } from "../actions/userActions";
import alertify from "alertifyjs";
import { LOCAL_STORAGE_CUSTOMER_DATA_KEY } from "../constants";
import { actions } from "../actions";
import * as errorCodes from "../utils/errorCodes";
import { addMinutes } from "date-fns";
import { createBrowserHistory } from 'history';

function Login({  
  authenticatedUser,
  errorCode,
  errorMessage,
  loading,
  isAuthenticated,
  lockStartTime,
  recentUrl,
  login,    
  fetchProfile,
  closeError,
  clearLoading,
  updateWalletBalance,
  redirectToRecentUrl,
}) {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [savedCustomerData, setSavedCustomerData] = useState({});
  const history = createBrowserHistory();

  const startTime = addMinutes(lockStartTime, 60).getTime();
  const date = new Date();
  const timerTime = startTime - date.getTime();
  const [timer, setTimer] = useState(timerTime > 0 ? timerTime : 0);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProfile(authenticatedUser.userId);
      if((window !== undefined) && (window.location !== undefined) && (window.location.state !== undefined) && (window.location.state.requestedPath !== undefined)){
        history.push({
          pathname: window.location.state?.requestedPath,
          search: window.location.search,
        });
      } else{
        history.push({
          pathname: "/user/bookings",
          search: window.location.search,
        });
      }
      window.location.reload();
    // } else {
    //   persister.purge();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    clearLoading();
  }, []);

  useEffect(() => {
    try {
      const cacheData = JSON.parse( window.localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY) || "{}" ) || {};
      setSavedCustomerData(cacheData);
      if (cacheData && cacheData.email?.email) {
        setUsername(cacheData.email?.email)
      }
    } catch (e) {}
  }, []);

  const resetErrors = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_CUSTOMER_DATA_KEY,
      JSON.stringify({
        ...savedCustomerData,
        lockStartTime: null,
        errorCode: null,
      })
    );
    setSavedCustomerData({})
    closeError();
  };

  const handleChange = (e) => {
    if (e.target.id === "loginUsername") {
      setUsername(e.target.value)
    }
    if (e.target.id === "loginPassword") {
      setPassword(e.target.value)
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var username_el = document.getElementById('loginUsername');
    var password_el = document.getElementById('loginPassword');

    username_el.style.border = "1px solid #ddd";
    password_el.style.border = "1px solid #ddd";	
	
    if(username === ""){
      alertify.notify('Email Address / Phone cannot be empty', 'custom', 2, function(){});
      username_el.style.border = "1px solid #F98575";
      return;
    }else{
      username_el.style.border = "1px solid #ddd";
    }    

    if(password === ""){
      alertify.notify('Password can not be empty', 'custom', 2, function(){});
      password_el.style.border = "1px solid #F98575";
      return;
    }else{
      password_el.style.border = "1px solid #ddd";
    }

    let data = {};
    if(pattern.test(username)){
      data = { 
        "email": username, 
        "password": password 
      }
    }else{
      data = { 
        "phone": username, 
        "password": password 
      }
    }
    login(data);
  }
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (timer > 0) {
        const date = new Date();
        const timerTime = startTime - date.getTime();
        if (timerTime > 0) {
          setTimer(timerTime);
        }
      }
    }, 1000);
    // clear it if time is less than a second
    if (timer <= 999) {
      resetErrors();
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  const parseErrorCode = () => {
    if (!errorCode) {
      return null;
    }

    if (errorCode === errorCodes.LOGIN_FAILED) {
      return savedCustomerData.email ? "Your entered password does not match" : errorMessage;
    }

    if (errorCode === errorCodes.ATTEMPTS_EXCEEDED) {
      return `You have exhausted all attempts. Reset your password or try again after ${1}hr.`
    }
    
    if (errorCode !== "GENERIC_ERROR") {
      return "An unexpected error occurred. Please try again later.";
    }

    if (errorCode === errorCodes.GENERIC_ERROR) {
      if(errorMessage){
        return errorMessage;
      }
      return "An unexpected error occurred. Please try again later.";
    }
    return errorMessage;
  }

  return (
    <>
      <Header />
      <CustomNavbar />
      <section className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
        <Container>
          <Row className="justify-content-md-center align-items-center">
            <div className="block-content">
              <div className="block-title">
                <h3>Welcome Back to Sharearide</h3>
                {errorCode && (!savedCustomerData || !savedCustomerData.email) && (
                  <div style={{color: "red"}}>
                    <div className="alert-text font-weight-bold">{parseErrorCode()}</div>
                  </div>
                )}
                {!errorCode && <h5>Sign in to your account to continue using Sharearide</h5>}
              </div>
              <div className="content">
                <Row className="align-items-left" style={{ marginTop:10.5}}>
                  <Col xs={12}>
                    { loading ? 
                      <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                          <Spinner animation="grow" variant='success' />
                        </div>
                      </div>: 
                      <form id="form-login" >
                        <div className="form-content">
                          <div className="form-group">
                            <label htmlFor="loginUsername"> Email Address / Phone (eg +267 71000000) </label>
                            <input
                              type="text"
                              name="jaName"
                              id="loginUsername"
                              className="form-control"
                              value={username}
                              onChange={handleChange} />
                          </div>
                          <div className="form-group">
                            <label htmlFor="loginPassword">Password</label>
                            <input
                              type="password"
                              name="loginPassword"
                              id="loginPassword"
                              className="form-control"
                              placeholder=""
                              value={password}
                              onChange={handleChange} />
                          </div>
                          <Row className="justify-content-md-center align-items-left" style={{ marginTop:10.5}}>
                            <Col style={{ marginTop:10.5}} xs={7} md={9}>
                              <Link to="/forget-password">Forgot Password?</Link>
                            </Col>
                            <Col style={{float: "right"}} >
                              <input type="submit" className="form-control rounded" value="Login" onClick={handleClick}/>
                            </Col>
                          </Row>
                        </div>
                      </form>
                    }
                  </Col>                    
                </Row>
                <Row className="align-items-left foot-msg">
                  <Col xs={12}>
                    <span className="msg">
                      Not a member yet? <Link to="/signup">Sign up</Link> for free
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

function mapStateToProps(state) {
  return {
    authenticatedUser: state.Auth.user,
    errorCode: state.Auth.errorCode,
    errorMessage: state.Auth.errorMessage,
    loading: state.Loading.loading,
    isAuthenticated: state.Auth.isAuthenticated,
    lockStartTime: state.Auth.lockStartTime,
    recentUrl: state.recent_url
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: ( user, password, saveUserDataInCache ) => {
      dispatch(actions.auth.requestLogin( user, password, saveUserDataInCache ));
      dispatch(actions.loading.requestLoadingAction());
    },    
    fetchProfile: ( userId ) => {
      dispatch(actions.user.fetchUserDetails( userId));
    },
    closeError: () => {
      dispatch(actions.error.cancelErrorAction());
    },
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
    updateWalletBalance: (data) =>{
      dispatch(updateWalletBalance(data))
    },
    redirectToRecentUrl: (data) => {
      dispatch(redirectToRecentUrl(data))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
