import React from "react";
import { Container, Row, Button } from "reactstrap";
import axios from "axios";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import domtoimage from 'dom-to-image';
import { default as JSPDF } from 'jspdf';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
};

class Ticket extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            ticketDetails: {},
            qrCodeData: ''
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props?.Auth?.token,
            },
        };
        await axios.get(`${ENDPOINT}/app/v2/ticket-details/${this.props.global?.recent_booking_data?.uuid}`, config).then(res=>{
            const { data, success } = res?.data;
            if(success){
                const qrData = {
                    usernames: `${data?.fname} ${data?.lname}` ,
                    reservationNumber: data?.reservation_number,
                    date: data?.booking_Date,
                    departure: data?.departure, 
                    destination: data?.arrival, 
                    departureTime: data?.departureTime, 
                    busOperator: data?.partner_id, 
                    busRegistrationNumber: data?.reg_no, 
                    seats: data?.seats, 
                    ticketPrice: data?.fare, 
                    totalPrice: data?.ticket_Price
                }
                this.setState({
                    ticketDetails: data[0],
                    qrCodeData: qrData
                });
            }
        });         
        
        this.setState({
            loading: false,
        });
    }

    render(){
        const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const d = new Date(this.state?.ticketDetails?.booking_date);
        var mon = d.getMonth();
        mon = month[mon];
        const date = d.getDate();      
        
        return (            
            <>
                <section className="page-single bg-grey">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                {!this.state.loading? 
                                    <Carousel responsive={responsive} >
                                        <div key={this.state?.ticketDetails?.uuid}>
                                            <div style={{width: '300px', margin: '0 auto'}}>
                                                <img src={require('../assets/images/files/ticket-top.png')} />
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                <div className="ticket-outer" id={"ticket" + this.state?.ticketDetails?.uuid} style={{width: '300px'}}>
                                                    <div className="ticket-inner">
                                                        <div className="ticket-heading">ticket # { this.state?.ticketDetails?.reservation_number }</div>
                                                        <div className="ticket-description-1">
                                                            <div className="ticket-description-1-heading" style={{display: 'inline-block'}}>{this.state?.ticketDetails?.name}</div>
                                                            <div className="ticket-description-1-date">{ mon + ' ' + date }, { this.state?.ticketDetails?.departure }</div>
                                                        </div>
                                                        <div className="ticket-description-2 mt-5">
                                                            <div className="ticket-description-2-left">
                                                                <div className="bus-service">Bus Service</div>
                                                                <div className="station"><b> Sharearide </b></div>
                                                            </div>
                                                            <div className="ticket-description-2-right">
                                                                <div className="bus-service">Booking  Seats</div>
                                                                <div className="station station-2">{this.state?.ticketDetails?.seats?.replace(/'/g, '').split(',')}</div>
                                                            </div>                                    
                                                        </div>
                                                        <div className="ticket-description-2 mt-5 mb-5">
                                                            <div className="ticket-description-2-left">
                                                                <div className="bus-service">Boarding</div>
                                                                <div className="station"><b>  {this.state?.ticketDetails?.departureTime}</b></div>
                                                                <div className="station time">({this.state?.ticketDetails?.departure})</div>
                                                            </div>
                                                            <div className="ticket-description-2-right">
                                                                <div className="bus-service">Drop</div>
                                                                <div className="station"><b>  {this.state?.ticketDetails?.arrivalTime}</b></div>
                                                                <div className="station time">({this.state?.ticketDetails?.arrival})</div>
                                                            </div>                                    
                                                        </div>
                                                        <div className="ticket-description-2 mt-5 mb-3">
                                                            <QRCode value={JSON.stringify(this.state?.qrCodeData)} />
                                                        </div>                                
                                                    </div>                                
                                                </div>
                                            </div>
                                            <div style={{width: '300px', margin: '0 auto'}}>
                                                <img src={require('../assets/images/files/ticket-bottom.png')} />
                                            </div>
                                            <div style={{width: '300px', margin: '0 auto'}}>
                                                <Button id={'ticket' + this.state?.ticketDetails?.uuid +'-btn'} style={{marginTop: '20px', paddingTop: '10px', paddingBottom: '10px', border: 'none'}} onClick={async ()=>{
                                                    var el = document.getElementById('ticket' + this.state?.ticketDetails?.uuid);
                                                    var img_src;
                                                    await domtoimage.toPng(el).then( function (dataUrl) {
                                                        var img = new Image();
                                                        img.src = dataUrl;
                                                        img_src = dataUrl;
                                                    });
                                                    const doc = new JSPDF();
                                                    doc.addImage(img_src, "PNG", 5, 5);
                                                    doc.save('ticket.pdf');
                                                }}>print</Button>
                                            </div>
                                        </div>
                                    </Carousel>
                                :
                                ''}
                                <Container>
                                    <Row/>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(Ticket);