import React from "react"; 
// reactstrap components
import { Col, Container, Row } from "reactstrap";
// core components
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
//terms item list json file
import termList from "./termList";

class TermsAndConditions extends React.Component {
  constructor() {
    super();
    this.state = {
      terms: termList,
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  render() {
    const termList = this.state.terms.map((item, i) => {
      return (
        <React.Fragment key={i}>
          <h4 className="mb-4">{item.heading}</h4>
          <p className="mb-5">{item.text}</p>
        </React.Fragment>
      );
    });

    return (
      <>
        <Header />
        <CustomNavbar />
        <div id="section-terms-conditions" className="section-flat">
          <div className="section-content">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="section-title text-center">
                    <span>
                      <em></em>
                    </span>
                    <h2 className="mt-4">
                      Terms and Conditions
                    </h2>
                    {/* <p>
                      Cum doctus civibus efficiantur in imperdiet deterruisset.
                    </p> */}
                  </div>
                </Col>

                <div className="clearfix"></div>
                <Col sm={12}>
                  {/* <h4 className="mb-3 privacy-heading">1	ROLE OF SHARE A RIDE</h4> */}
                  <ol>
                    <li className="mb-5 privacy-heading">ROLE OF SHARE A RIDE
                      <ol type="I">
                        <li className="li-inner">Share-A-Ride only provides a technology platform that connects intending travelers with bus operators. It does not operate any bus or offer the service of transportation to the user. Share-A-Ride also does not act as an agent of any bus operator in the process of providing the above mentioned technology platform services</li>
                        <li className="li-inner">Share-A-Ride acts as a facilitator connecting intending customers to bus operators offering sale of tickets or bus seats. Accordingly, the contract of sale of services on the website is strictly a bipartite contract between the bus operators listed on the website.</li>
                      </ol>
                    </li>
                    <li className="mb-5  privacy-heading">LIMITATION OF LIABILITY OF SHARE-A-RIDE
                      <ol type="I">
                        <li className="li-inner">In its role as a technology platform to facilitate transactions between the bus operators and the users, Share-A-Ride shall not be responsible for the operations of the bus operator including, but not limited to the following:
                          <ol type="a">
                            <li>Timely Departure or arrival of the bus;</li>
                            <li>The conduct of bus operator’s employees, representatives or agents;</li>
                            <li>The condition of the bus, seats etc. not being up to the customers’ expectations or as per the description provided by the bus operator</li>
                            <li>Cancellation of the trip due to any reasons;</li>
                            <li>Loss or damage of the baggage of the customer;</li>
                            <li>The bus operator changing a customer’s seat for whatever reason;</li>
                            <li>Bus operator informing a wrong boarding point for the issuance of the booking confirmation voucher, or changing such boarding point eventually with or without any notification to Share-A-Ride or the user;</li>
                            <li>Bus Operator using a separate pick up vehicle to transport the user from the designated boarding point to the actual place of departure of the bus.</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li className="mb-5  privacy-heading">RESPONSIBILITIES OF THE USER
                      <ol type="a">
                        <li className="li-inner">Users are advised to call the bus operator to find out the exact boarding point, or any information which they may need for the purpose of boarding or travel in that trip</li>
                        <li className="li-inner">At the time of boarding the bus, users shall furnish a copy of the ticket, and any valid identity proof (e.g. Identity Card, Passport or Driver license) or any other proof issued by a government entity</li>
                        <li className="li-inner">Users are required to reach the boarding place at least 30 minutes before the scheduled departure time.</li>
                        <li className="li-inner">All tickets issued shall be non-transferable.</li>
                      </ol>
                    </li>
                    <li className="mb-5  privacy-heading">CANCELLATION OF TICKET
                      <ol>
                        <li className="li-inner">Cancellation of tickets can be done either through the Users login on the Share-A-Ride website or mobile application, or by calling on the customer care number;</li>
                        <li className="li-inner">Any cancellation is subject to such cancellation charges as mentioned on the ticket.</li>
                        <li className="li-inner">User needs to compulsorily obtain a bus ticket at the regular fare in case a child above the age of 5 years is accompanied by them, unless otherwise a particular bus operator specifies otherwise in their terms and conditions.</li>
                      </ol>
                    </li>
                  </ol>
                </Col>
                
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default TermsAndConditions;
