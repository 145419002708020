import { call } from "redux-saga/effects";
import { events } from "../actions";
import { onRequestError, onCompleteRequest, formatRequest } from "./common";
import axios from "../config/axiosConfig";
import * as errorCodes from "../utils/errorCodes";
import { ForgotPassProfile, } from "../utils/analyticsCustomEvents";

function changePassword(username) {
  return axios(
    formatRequest(
      `/api/app/uaa/v1/change-password`,
      "post",
      {
        email: username,
      },
      true,
      true
    )
  );
}

export function* requestChangePassword(action) {
  try {
    const { data: apiResponse = {} } = yield call(
      changePassword,
      action.emailId,
    );
    const { success, data = {}, errors = [], message } = apiResponse;

    if (success) {
      yield onCompleteRequest(events.changePassword.RECEIVED, data);
    } else {
      const errorObject = errors.length > 0 ? errors[0] : {};
      const errorCode = errorObject.errorCode || errorCodes.GENERIC_ERROR;
      const errorMsgFromBE = errorObject.errorMsg || message || "";      
      yield onRequestError(
        events.changePassword.FAILED,
        errorCode,
        errorMsgFromBE
      );
    }
  } catch (e) {
    yield onRequestError(
      events.changePassword.FAILED,
      errorCodes.GENERIC_ERROR,
      e.message
    );
  }
}
