import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { connect } from 'react-redux';
import alertify from 'alertifyjs';
import axios from "axios";
import { profileUpdate } from '../actions/userActions';
import { ENDPOINT } from "../constants";
import UserSidebar from "./UserDashboardSidebar";
import { actions } from "../actions";
import { persister } from "../redux/store";

function ProfilePage ({
  userDetails,
  auth,
  addressDetails,
  fetchProfile,
  userId,
  token,
  profileUpdate,
  closeError,
  clearLoading
}){
  const [processing, setProcessing] = useState(true);
  const [user, setUser] = useState(userDetails);
  const [address, setAddress] = useState(addressDetails);
  const [newPassword, setNewPassword] = useState(null);  
  const [confirmPassword, setConfirmPassword] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      await fetchProfile(userId);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if(userDetails){
      setUser(userDetails)
    }
  }, [userDetails]);

  useEffect(() => {
    if(!user){
      fetchProfile(userId)
    }
    setProcessing(false)
  }, [user]);

  const handlePasswordSubmit = async (e) =>{
    e.preventDefault();

    var new_password_el = document.getElementById('newResetPassword');
    var confirm_password_el = document.getElementById('confirmResetPassword');

    if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{6,20})/.test(newPassword)){
      if(newPassword === ""){
        alertify.notify('New password cannot be empty', 'custom', 2, function(){});
        new_password_el.style.border = "1px solid #F98575";
        return;
      }else{
        alertify.notify('New password should be between 6 to 20 characters long', 'custom', 2, function(){});
        new_password_el.style.border = "1px solid #F98575";
        return;
      }
    }else{
      new_password_el.style.border = "1px solid #ddd";
    }

    if(newPassword !== confirmPassword){
      confirm_password_el.style.border = "1px solid #F98575";
      alertify.notify('Password do not match', 'custom', 2, function(){});
    }else{
      confirm_password_el.style.border = "1px solid #ddd";
      const token = token;
      const config = {
        headers: {
          'content-type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      };
      await axios.post(ENDPOINT+'/reset-password',{ user_id: userId, password: newPassword }, config)
      .then((response) => {
        const { data, success } = response?.data;
        if (!success) {
          alertify.notify(data?.message, 'custom', 2, function(){});
        }else{
          alertify.notify(data?.message, 'customsuccess', 2, function(){});
        }
      }).catch((error) => {
          alertify.notify(error.message, 'custom', 2, function(){});
          if(error.message === 'Request failed with status code 401'){
            persister.purge();
          }
      });
    }
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    var fname_el = document.getElementById('hbFirstName');
    var lname_el = document.getElementById('hbLastName');
    
    if(!/^[a-zA-Z]+$/.test(user?.fname)){
      if(user?.fname === ""){
        alertify.notify('First name cannot be empty', 'custom', 2, function(){});
        fname_el.style.border = "1px solid #F98575";
        return;
      }else{
        alertify.notify('First name should only contain alphabets', 'custom', 2, function(){});
        fname_el.style.border = "1px solid #F98575";
        return;
      }
    }else{
        fname_el.style.border = "1px solid #ddd";
    }

    if(!/^[a-zA-Z]+$/.test(user?.lname)){
      if(user?.lname === ""){
        alertify.notify('Last name cannot be empty', 'custom', 2, function(){});
          lname_el.style.border = "1px solid #F98575";
          return;
      }else{
        alertify.notify('Last name should only contain alphabets', 'custom', 2, function(){});
        lname_el.style.border = "1px solid #F98575";
        return;
      }
    }else{
        lname_el.style.border = "1px solid #ddd";
    }
    setProcessing(true)
    const data = {
      "data":{
        "user":{
          "id": user?.id,
          "uuid": user?.uuid,
          "fname": user?.fname,
          "lname": user?.lname,
          "phone": user?.phone,
          "image": user?.image,
          "role": user?.role,
          "status": user?.status,
          "deleted": false,
          "address": (address?.uuid !== undefined && address?.uuid !== null ? address?.uuid : null),
          "createdAt": user?.createdAt,
          "updatedAt": user?.updatedAt,
          "deletedAt": null,
          "version": user?.version
        },
        "address":{
          "id": (address?.id !== undefined && address?.id !== null ? address?.id : null),
          "uuid": (address?.uuid !== undefined && address?.uuid !== null ? address?.uuid : null),
          "address": (address?.address !== undefined && address?.address !== "" ? address?.address : null),
          "city": (address?.city !== undefined && address?.city !== "" ? address?.city : null),
          "zipCode": (address?.zipCode !== undefined && address?.zipCode !== "" ? address?.zipCode : null),
          "country": (address?.country !== undefined && address?.country !== "" ? address?.country : null),
          "createdAt": (address?.createdAt !== undefined && address?.createdAt !== null ? address?.createdAt : null),
          "updatedAt": (address?.updatedAt !== undefined && address?.updatedAt !== null ? address?.updatedAt : null),
          "deletedAt": null,
          "version": (address?.version !== undefined && address?.version !== null ? address?.version : 0)
        }
      }
    }
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      dataType: "json",
      method: 'put',
      url: `${ENDPOINT}/app/v1/user/${userId}/edit`,
      data : data
    };
    await axios(config).then(async (response) => {      
      const { data, success } = response?.data;
      if (success) {
        await fetchProfile(userId);
        profileUpdate(data);     
        alertify.notify('Profile updated successfully', 'customsuccess', 2, function(){});
      }
    }).catch(async ()=>{
      alertify.notify('Profile updated failed', 'custom', 2, function(){});
    }).finally(()=>{
      setProcessing(false)
    })
  }

  const handleChange = (e) =>{
    if(e.target.id === 'hbFirstName'){
      setUser({
        ...user,
        fname: e.target.value
      })
    }
    if(e.target.id === 'hbLastName'){
      setUser({
        ...user,
        lname: e.target.value
      })
    }
    if(e.target.id === 'hbAddress'){
      setAddress({
        ...address,
        address: e.target.value
      })
    }
    if(e.target.id === 'hbCity'){
      setAddress({
        ...address,
        city: e.target.value
      })
    }
    if(e.target.id === 'hbZipCode'){
      setAddress({
        ...address,
        zipCode: e.target.value
      })
    }
    if(e.target.id === 'hbCountry'){
      setAddress({
        ...address,
        country: e.target.value
      })
    }
    if(e.target.id === 'hbPicture'){
      var fileInput = document.getElementById('hbPicture');        
      var filePath = fileInput.value;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;         
      if (!allowedExtensions.exec(filePath)) { 
        alertify.notify('Invalid file type', 'custom', 2, function(){});
        fileInput.value = ''; 
        return false; 
      } else  {        
        if (fileInput.files && fileInput.files[0]) { 
          const _self = this;
          var reader = new FileReader();
          reader.onload = function(e) { 
            _self.setState({
              image: e.target.result
            });
            document.getElementById( 'imagePreview').innerHTML = '<img style="width: 200px" src="' + e.target.result + '"/>';
          };
          reader.readAsDataURL(fileInput.files[0]);
        } 
      }
    }
    if(e.target.id === 'newResetPassword'){
      setNewPassword(e.target.value);
    }
    if(e.target.id === 'confirmResetPassword'){
      setConfirmPassword(e.target.value)
    }
  }

  return (
    processing ? 
      <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
          <Spinner animation="grow" variant='success' />
        </div>
      </div>:
    <>
      <section id="content" className="page-single bg-grey with-sidebar">
        <div id="content-wrap">
          <div className="section-flat">
            <div className="section-content">
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="page-single-content sidebar-left">
                      <Row>
                        <Col lg={3} md={12} className="mb-4"><UserSidebar/></Col>
                        <Col lg={9} md={12}>
                          <div className="content-main">
                            <div className="block-content-2">
                              <div className="block-title"><h3>Account Info</h3></div>
                              <Form id="form-hotel-booking" onSubmit={handleSubmit}>
                                <div className="form-content">
                                  <FormGroup>
                                    <div className="fields-row fields-2">
                                      <div className="box-field">
                                        <Label for="hbFirstName">First Name</Label>
                                        <Input
                                          type="text"
                                          name="hbFirstName"
                                          id="hbFirstName"
                                          className="form-control"
                                          placeholder=""
                                          value={user?.fname || user?.firstName}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      <div className="box-field">
                                        <Label for="hbLastName">
                                          Last Name
                                        </Label>
                                        <Input
                                          type="text"
                                          name="hbLastName"
                                          id="hbLastName"
                                          className="form-control"
                                          placeholder=""
                                          value={user?.lname || user?.lastName}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <div className="fields-row fields-2">
                                      <div className="box-field">
                                        <Label for="hbEmail">
                                          Email Address
                                        </Label>
                                        <Input
                                          type="text"
                                          name="hbEmail"
                                          id="hbEmail"
                                          className="form-control"
                                          placeholder=""
                                          disabled
                                          value={auth?.email}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* .box-field end */}
                                      <div className="box-field">
                                        <Label for="hbEmailConfirm">
                                          Contact Number
                                        </Label>
                                        <Input
                                          type="text"
                                          name="hbContactNumber"
                                          id="hbContactNumber"
                                          className="form-control"
                                          placeholder=""
                                          disabled
                                          value={user?.phone}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* .box-field end */}
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <div className="fields-row fields-2">
                                      <div className="box-field">
                                        <Label for="hbStreet">
                                          Address
                                        </Label>
                                        <Input
                                          type="text"
                                          name="hbAddress"
                                          id="hbAddress"
                                          className="form-control"
                                          placeholder=""
                                          value={address?.address}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* .box-field end */}
                                      <div className="box-field">
                                        <Label for="hbTownCity">
                                          City
                                        </Label>
                                        <Input
                                          type="text"
                                          name="hbCity"
                                          id="hbCity"
                                          className="form-control"
                                          placeholder=""
                                          value={address?.city}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* .box-field end */}
                                    </div>
                                    {/* .fields-row end */}
                                  </FormGroup>
                                  <FormGroup>
                                    <div className="fields-row fields-2">
                                      <div className="box-field">
                                        <Label for="hbZipCode">
                                          ZIP Code
                                        </Label>
                                        <Input
                                          type="text"
                                          name="hbZipCode"
                                          id="hbZipCode"
                                          className="form-control"
                                          placeholder=""
                                          value={address?.zipCode}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* .box-field end */}
                                      <div className="box-field">
                                        <Label for="hbCountry">Country</Label>
                                        <Input
                                          type="text"
                                          name="hbCountry"
                                          id="hbCountry"
                                          className="form-control"
                                          placeholder=""
                                          value={address?.country}
                                          onChange={handleChange}
                                        />
                                      </div>
                                      {/* .box-field end */}
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <div className="fields-row fields-2">
                                      <div className="box-field">
                                        <Label for="hbZipCode"> Username </Label>
                                        <Input type="text" name="hbZipCode" id="hbZipCode" className="form-control" placeholder="" disabled value={auth?.username} onChange={handleChange} />
                                      </div>
                                      {/* <div className="box-field">
                                        <Label for="hbCountry">Picture</Label>
                                        <div id="imagePreview">
                                          {user?.image !== null ? <img style="width: 200px" src={new Buffer.from(user?.image).toString('ascii')} />:
                                          <img style="width: 200px" src={require('../assets/images/files/user_avatar.png')}/>}
                                        </div>
                                        <Input
                                          type="file"
                                          name="hbPicture"
                                          id="hbPicture"
                                          className="form-control"
                                          onChange= {handleChange} 
                                          accept=".jpg,.jpeg,.png"
                                        />
                                      </div> */}
                                    </div>
                                  </FormGroup>
                                  <FormGroup>
                                    <Input
                                      type="submit"
                                      className="form-control"
                                      value="Update Profile"/>
                                  </FormGroup>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>                        
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) =>{
  return {
    ...state,
    userDetails: state?.profile?.details?.user || state?.Auth?.user || {},
    auth: state?.profile?.details?.authDetails || {},
    addressDetails: state?.profile?.details?.address || {},
    userId: state?.Auth?.user?.userId,
    token: state?.Auth?.token
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: ( userId ) => {
      dispatch(actions.user.fetchUserDetails( userId));
    },
    profileUpdate: (data) =>{
      dispatch(profileUpdate(data));
      dispatch(actions.loading.requestLoadingAction());
    },
    closeError: () => {
      dispatch(actions.error.cancelErrorAction());
    },
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
