import { events } from "../actions";
import { put, call } from "redux-saga/effects";
import axios from "../config/axiosConfig";
import qs from "qs";
import store from "../redux/store";
import jwt from "jsonwebtoken";
import { TOKEN, ID_TOKEN, REFRESH_TOKEN_CHECK_INTERVAL } from "../constants";

const { REACT_APP_API_VERSION } = process.env || 'v1';
const API_KEY = process.env.REACT_APP_API_KEY || "x32jcsHA3240SSdfdk";
const API_TIMEOUT = 1000 * 60; // 60 seconds

export const formatRequest = ( path, method, data, useAuth = true, useIdtoken = false, timeout = API_TIMEOUT, customHeader = null ) => {
  const apiVersion = 'v1';//process.env.REACT_APP_API_VERSION;
  let headers = {
    "x-api-key": API_KEY,
    "x-api-version": apiVersion,
    "x-user-platform": "WEB",
  };
  if (useAuth) {
    const accessToken = window.localStorage.getItem(TOKEN);
    headers["authorization"] = `Bearer ${accessToken}`;
  }
  if (useIdtoken) {
    headers["x-id-token"] = window.localStorage.getItem(ID_TOKEN);
  }

  if (customHeader) {
    headers = { ...headers, ...customHeader };
  }

  let query = `${qs.stringify({ ...(method === "get" ? data : undefined), })}`;

  const url = `${path.substring(4)}${query !== "" ? "?" + query : ""}`;
  return { method, url, data, headers, timeout };
};

export function isJwtValid() {
  const token = window.localStorage.getItem(TOKEN);
  if (!token) return false;
  const date = new Date().getTime() / 1000;
  const data = jwt.decode(token);
  if (!data) return false;
  return date < data.exp - (REFRESH_TOKEN_CHECK_INTERVAL / 1000) * 3;
}

export function renewJWTToken(userId, refreshToken) {
  return axios(
    formatRequest(
      `/api/app/uaa/${'v1'}/token/renew`,
      "post",
      {
        userId: userId,
        refreshToken:refreshToken,
      },
      true
    )
  );
}

export function configureAuthentication() {
  store.subscribe(() => {
    const state = store.getState() || {};
    const storedToken = window.localStorage.getItem(TOKEN);
    const auth = state.Auth || {};    
    if (!auth.isAuthenticated && storedToken) {
      window.localStorage.removeItem(TOKEN);
      window.localStorage.removeItem(ID_TOKEN);
    }
  });
  // refresh auth token if token lost more than half of it's lifetime
  window.setInterval(checkAuthStatus, REFRESH_TOKEN_CHECK_INTERVAL);
}
// Javascript/setInterval based auth token update
export const checkAuthStatus = async () => {
  let isJwtValidToken = isJwtValid();  
  if (!isJwtValidToken) {
    const state = store.getState() || {};
    const auth = state.Auth;
    if (auth && auth.token && auth.refreshToken && auth.user) {
      try {
        const { data: apiResponse } = await renewJWTToken(
          auth.user.userId,
          auth.refreshToken
        );
        const { success, data = {} } = apiResponse;
        if (success && data.token) {
          window.localStorage.setItem(TOKEN, data.accessToken);
          window.localStorage.setItem(ID_TOKEN, data.idToken);
          isJwtValidToken = true;
          store.dispatch({
            type: events.auth.RECEIVED_REFRESH_JWT_TOKEN,
            payload: data,
          });
        }
      } catch (err) {
        // resfresh token is invalid
        window.localStorage.removeItem(TOKEN);
        window.localStorage.removeItem(ID_TOKEN);
      }
    }

    if (!isJwtValidToken) {
      store.dispatch({
        type: events.auth.FAILED_REFRESH_JWT_TOKEN,
      });
    }
  }
};

export function* onRequestError(eventType, errorCode, errorMessage, params) {
  if (Array.isArray(eventType)) {
    for (const type of eventType)
      yield put({ type, message: errorMessage, errorCode, params });
  } else {
    yield put({ type: eventType, message: errorMessage, errorCode, params });
  }

  yield put({
    type: events.error.RECEIVED,
    message: errorMessage,
    errorCode,
    params,
  });
  yield put({ type: events.loading.COMPLETED });
}

export function* onCompleteRequest(eventType, data) {
  if (Array.isArray(eventType)) {
    for (const type of eventType) yield put({ type, payload: data });
  } else { 
    yield put({ type: eventType, payload: data });
  }
  yield put({ type: events.loading.COMPLETED });
}
// This can be used to call API with auth token
export function* callRestricatedAPI(fn, ...args) {
  let isJwtValidToken = isJwtValid();
  if (!isJwtValidToken) {
    const state = store.getState() || {};
    const auth = state.Auth;
    if (auth && auth.token && auth.refreshToken && auth.user) {
      try {
        const { data: apiResponse } = yield renewJWTToken(
          auth.user.userId,
          auth.refreshToken
        );
        const { success, data = {} } = apiResponse;
        if (success && data.accessToken) {
          window.localStorage.setItem(TOKEN, data.accessToken);
          window.localStorage.setItem(ID_TOKEN, data.idToken);
          isJwtValidToken = true;
          yield put({
            type: events.auth.RECEIVED_REFRESH_JWT_TOKEN,
            payload: data,
          });
        }
      } catch (err) {
        // resfresh token is invalid
        window.localStorage.removeItem(TOKEN);
        window.localStorage.removeItem(ID_TOKEN);
      }
    }
  }

  if (isJwtValidToken) {
    return yield call(fn, ...args);
  } else {
    yield put({
      type: events.auth.FAILED_REFRESH_JWT_TOKEN,
    });
  }
}
// REDUX-SAGA style to update token
export function* updateToken() {
  try {
    const state = store.getState() || {};
    const auth = state.Auth;
    const { data: apiResponse } = yield renewJWTToken(
      auth.user.userId,
      auth.refreshToken
    );
    const { success, data = {} } = apiResponse;
    if (success && data.accessToken) {
      window.localStorage.setItem(TOKEN, data.accessToken);
      window.localStorage.setItem(ID_TOKEN, data.idToken);
      yield put({
        type: events.auth.RECEIVED_REFRESH_JWT_TOKEN,
        payload: data,
      });
    }
  } catch (err) {
    window.localStorage.removeItem(TOKEN);
    window.localStorage.removeItem(ID_TOKEN);
    yield put({
      type: events.auth.FAILED_REFRESH_JWT_TOKEN,
    });
  }
}
