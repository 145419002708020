import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Verify from "./views/Verify";
import Search from "./views/Search";
import LandingPage from "./views/LandingPage";
import UserLayout from "./components/Layout/User";
import ForgetPassword from "./views/ForgetPassword";
import NewPassword from "./views/NewPassword";
import Faqs from "./views/Faqs";
import PrivacyPolicy from "./views/PrivacyPolicy";
import UserAgreement from "./views/UserAgreement";
import TermsAndConditions from "./views/TermsAndConditions";
import RefundPolicy from "./views/RefundPolicy";
import ContactUs from "./views/ContactUs";
import About from "./views/About";
import Blog from "./views/Blog";
import SingleBlog from "./views/SingleBlog";
import Error from "./views/Error";
import PaymentWebhooks from "./views/PaymentWebhooks";

const Routes = () =>{
  return (
    <Switch>
      <Route path="/payment/dpogroup/webhooks" exact render={(props) => <PaymentWebhooks {...props} />} />
      <Route path="/signup" exact render={(props) => <SignUp {...props} />} />
      <Route path="/verify" exact render={(props) => <Verify {...props} />} />
      <Route path="/login" exact render={(props) => <Login {...props} />} />
      <Route path="/user" render={(props) => <UserLayout {...props} />} />
      <Route path="/search" exact render={(props) => <Search {...props} />} />
      <Route path="/forget-password" exact render={(props) => <ForgetPassword {...props} />} />
      <Route path="/forget-password/:token" exact render={(props) => <NewPassword {...props} />} />
      <Route path="/" exact render={(props) => <LandingPage {...props} />} />
      <Route path="/faqs" exact render={(props) => <Faqs {...props} />} />
      <Route path="/privacy_policy" exact render={(props) => <PrivacyPolicy {...props} />} />
      <Route path="/terms_conditions" exact render={(props) => <TermsAndConditions {...props} />} />
      <Route path="/refund_policy" exact render={(props) => <RefundPolicy {...props} />} />
      <Route path="/user_agreement" exact render={(props) => <UserAgreement {...props} />} />
      <Route path="/contact_us" exact render={(props) => <ContactUs {...props} />} />
      <Route path="/about" exact render={(props) => <About {...props} />} />
      <Route path="/blog" exact render={(props) => <Blog {...props} />}/>
      <Route path="/post/:id" exact render={(props) => <SingleBlog {...props} />}/>
      <Route path="/error" exact render={(props) => <Error {...props} />}/>
    </Switch>
  );
}
  
export default Routes;
