import React from "react";
import { Container, Row, Col, Spinner} from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import axios from "axios";
import { connect } from 'react-redux';
import { loginSuccess } from '../actions/userActions';
import alertify from "alertifyjs";
import { ENDPOINT } from "../constants";

const instance = axios.create();
instance.interceptors.request.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

class NewPassword extends React.Component {
  constructor(props){
    super(props);    
    this.state = {
      new_password: '',
      confirm_password: '',
      user_id: '',
      password_reset_token: '',
      processing: false
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0);
    this.setState({
      password_reset_token: this.props.match.params.token
    });
  }

  handleChange = (e) =>{
    if(e.target.id === 'newPassword'){
      this.setState({new_password: e.target.value});
    }
    if(e.target.id === 'confirmPassword'){
      this.setState({confirm_password: e.target.value});
    }
  }

  handleClick = async (e) =>{
    e.preventDefault();

    var new_password_el = document.getElementById('newPassword');
    var confirm_password_el = document.getElementById('confirmPassword');

    new_password_el.style.border = "1px solid #ddd";
    confirm_password_el.style.border = "1px solid #ddd";

    if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{6,20})/.test(this.state.new_password)){
      if(this.state.new_password === ""){
        alertify.notify('Password cannot be empty', 'custom', 2, function(){});
        new_password_el.style.border = "1px solid #F98575";
        return;
      }else{
        alertify.notify('Password should be between 6 to 20 characters long, and contains Lower case,upper case, number and special characters', 'custom', 2, function(){});
        new_password_el.style.border = "1px solid #F98575";
        return;
      }
    }else{
      new_password_el.style.border = "1px solid #ddd";
    }

    if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{6,20})/.test(this.state.confirm_password)){
      if(this.state.confirm_password === ""){
        alertify.notify('Confirm password cannot be empty', 'custom', 2, function(){});
        confirm_password_el.style.border = "1px solid #F98575";
        return;
      }
      if(this.state.new_password !== this.state.confirm_password){
        alertify.notify('Password do not match', 'custom', 2, function(){});  
        confirm_password_el.style.border = "1px solid #F98575";
        return;
      }
    }else{
      confirm_password_el.style.border = "1px solid #ddd";
    }
    this.setState({
      processing: true
    });
    const config = {
      method: 'PUT',
      dataType: "json",
      url: `${ENDPOINT}/app/uaa/v1/change-request/password/${this.state.password_reset_token}/${this.state.new_password}/token`,
      headers: {
        'content-type': 'application/json',
      },
    };
    await axios(config)
    .then(response => {
      if(response.status === 200){
        if(response.data){
          this.setState({
            processing: false
          });
          alertify.notify("Your password has been changed successfully", 'customsuccess', 2, function(){});
          this.props.history.push("/login");
        }
      }else{
        this.setState({
          processing: false
        });
        alertify.notify("Password change failed", 'custom', 2, function(){});
      }
    })
    .catch(error=> {
      this.setState({
        processing: false
      });
      alertify.notify("Password change failed", 'custom', 2, function(){});
    });
  }

  render() {
    return (
      <>
        <Header />
        <CustomNavbar />
        <section className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
          <Container>
            <Row className="justify-content-md-center align-items-center">
              <div className="block-content">
                <div className="block-title">
                  <h3>Forget Password</h3>
                  <h5>Please enter below new password to reset your password!</h5>
                </div>
                <div className="content">
                  <div className="left">
                    { this.state.processing ? 
                      <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                          <Spinner animation="grow" variant='success' />
                        </div>
                      </div>:
                      <form id="form-login" className="rounded">
                        <div className="form-content">
                          <div className="form-group">
                            <label htmlFor="loginUserEmail"> New Password </label>
                            <input
                              type="password"
                              name="newPassword"
                              id="newPassword"
                              className="form-control"
                              value={this.state.new_password}
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="loginUserEmail">
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              className="form-control"
                              value={this.state.confirm_password}
                              onChange={this.handleChange}
                            />
                          </div>
                          <Row className="justify-content-md-left align-items-left" style={{ marginTop:10.5}}>
                            <Col >
                            </Col>
                            <Col >
                              <input type="submit" className="form-control rounded" value="Submit" onClick={this.handleClick}/>
                            </Col>
                          </Row>
                        </div>
                      </form>
                    }
                  </div>
                  <div className="foot-msg">
                    <span className="msg">
                      Not a member yet? <Link to="/signup">Sign up</Link> for free
                    </span>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) =>{
  return {
    is_logged_in: state.is_logged_in,
    token: state.token,
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
      // deletePost: (id) =>{dispatch({type: 'DELETE_POST', id: id})},
      loginSuccess: (token) =>{dispatch(loginSuccess(token))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
