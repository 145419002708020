import React from "react";
import { Container, Input, Label, FormGroup, Spinner, Col } from "reactstrap";
import Footer from "../components/footers/Footer";
import Header from "../components/headers/Header";
import CustomNavbar from "../components/navbars/CustomNavbar";
import { connect } from 'react-redux';
import { signupSuccess } from '../actions/userActions';
import alertify from "alertifyjs";
import axios from "axios";
import { ENDPOINT } from "../constants";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

class SignUp extends React.Component {
  constructor(props){
    super(props);

    this.state = { 
      fname: '',
      lname: '',
      username: '',
      contactNumber: '',
      email: '',
      password: '',
      confirmpassword: '',
      processing: false,
      acceptedTerms: false,
      width: '300px'
    };

    this.handleclick = this.handleclick.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions();
  }

  handleChange = (e) =>{
    if(e.target.id === 'registerFirstname'){
      this.setState({fname: e.target.value});
    }
    if(e.target.id === 'registerLastname'){
      this.setState({lname: e.target.value});
    }
    if(e.target.id === 'registerUsername'){
      this.setState({username: e.target.value});
    }
    if(e.target.id === 'registerContactNumber'){
      this.setState({contactNumber: e.target.value});
    }
    if(e.target.id === 'registerEmail'){
      this.setState({email: e.target.value});
    }
    if(e.target.id === 'registerPassword'){
      this.setState({password: e.target.value});
    }
    if(e.target.id === 'registerPasswordConfirm'){
      this.setState({confirmpassword: e.target.value});
    }
  }

  handlePhoneChange = (value, data, event, formattedValue) =>{
    if(formattedValue !== undefined && formattedValue !== null){
      this.setState({
        contactNumber: formattedValue
      });
    }       
  }

  validatePhoneNumber = () =>{
    let valid = false;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      valid =  phoneUtil.isValidNumber(phoneUtil.parse(this.state.contactNumber));
    } catch(e) {
      valid = false;
    }
    return valid;
  }

  acceptTerms = async () =>{
    await this.setState({
      acceptedTerms: !this.state.acceptedTerms
    });
  }

  async handleclick(e){
    e.preventDefault();
    var fname_el = document.getElementById('registerFirstname');
    var lname_el = document.getElementById('registerLastname');
    var username_el = document.getElementById('registerUsername');
    var email_el = document.getElementById('registerEmail');
    var password_el = document.getElementById('registerPassword');
    var password_confirm_el = document.getElementById('registerPasswordConfirm');

    if(this.state.fname === ""){
      alertify.notify('First name cannot be empty', 'custom', 2, function(){});
      fname_el.style.border = "1px solid #F98575";
      return;
    }else{
      fname_el.style.border = "1px solid #ddd";
    }

    if(this.state.lname === ""){
      alertify.notify('Last name can not be empty', 'custom', 2, function(){});
      lname_el.style.border = "1px solid #F98575";
      return;
    }else{
      lname_el.style.border = "1px solid #ddd";
    }

    if(this.state.username === ""){
      alertify.notify('Username cannot be empty', 'custom', 2, function(){});
      username_el.style.border = "1px solid #F98575";
      return;
    }else{
      username_el.style.border = "1px solid #ddd";
    }

    if(this.state.contactNumber === ""){
      alertify.notify('Contact number cannot be empty', 'custom', 2, function(){});
      return;
    }else{
      if(!this.validatePhoneNumber()){
        alertify.notify('Invalid phone number', 'custom', 2, function(){});
        return;
      }
    }

    if(this.state.email !== ""){
      if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)){
        alertify.notify('Invalid email', 'custom', 2, function(){});
        email_el.style.border = "1px solid #F98575";
        return;          
      }else{
        email_el.style.border = "1px solid #ddd";
      }
    }

    if(!/^[A-Za-z\d@$!%^*#?&]{6,20}$/.test(this.state.password)){
      if(this.state.password === ""){
        alertify.notify('Password cannot be empty', 'custom', 2, function(){});
        password_el.style.border = "1px solid #F98575";
        return;
      }else{
        alertify.notify('Password should only contain 6 to 20 characters long', 'custom', 2, function(){});
        password_el.style.border = "1px solid #F98575";
        return;
      }
    }else{
      password_el.style.border = "1px solid #ddd";
    }

    if(this.state.password !== this.state.confirmpassword){
      alertify.notify('Password do not match', 'custom', 2, function(){});
      password_confirm_el.style.border = "1px solid #F98575";
      return;
    }else{
      password_confirm_el.style.border = "1px solid #ddd";
    }

    if(!this.state.acceptedTerms){
      alertify.notify('Please accept the terms of use first', 'custom', 2, function(){});
      return;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(this.state.contactNumber);
    const format = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
    this.setState({
      processing: true
    });
    const data = {
      "data": {
          "user":{
              "id": null,
              "uuid": null,
              "fname": this.state.fname, 
              "lname": this.state.lname, 
              "phone": format.replace(/ +/g, ""),
              "image": null,
              "role": "user",
              "status": "INACTIVE"
          },
          "address": null,
          "login":{
              "username": this.state.username, 
              "email": (this.state.email.length > 0 ? this.state.email : null),
              "password": this.state.password
          }
      }
    }   

    await this.registerUser(data).then(async response => {
      if(response?.data?.success){
        await this.setState({
          processing: false
        });
        alertify.notify('Account created successfully, please check your primary contact for OTP and verification steps', 'customsuccess', 2, function(){});
        this.props.signupSuccess(data);
        this.props.history.push("/verify");               
      } else{
        await this.setState({
          processing: false
        });
        if(Array.isArray(response?.data?.errors)){
          alertify.notify(response?.data?.errors[0]?.errorMsg, 'custom', 2, function(){});
          
        }else{
          alertify.notify('Server encountered errors processing your request', 'custom', 2, function(){});
          
        }
        return null;        
      }
    });
  }

  async registerUser(data){
    return await axios.post(`${ENDPOINT}/app/v1/user/create/`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      dataType: "json"
    });
  }

  async componentDidMount(){
    window.scrollTo(0, 0);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if(this.props.Auth.isAuthenticated){
      this.props.history.push("/user/profile");
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    if(window.innerWidth < 375){
      this.setState({ width: '300px'});
      return;
    } else if(window.innerWidth < 444){
      this.setState({ width: '317px'});
      return;
    } else if(window.innerWidth < 478){
      this.setState({ width: '350px'});
      return;
    } else if(window.innerWidth < 540){
      this.setState({ width: '400px'});
      return;
    }else if(window.innerWidth < 576){
      this.setState({ width: '442px'});
      return;
    } else if(window.innerWidth < 670){
      this.setState({ width: '460px'});
      return;
    } else if(window.innerWidth < 768){
      this.setState({ width: '460px'});
      return;
    } else {
      this.setState({ width: '600px'});
      return;
    }
  }

  render() {
    const disabled = (this.state.processing || !this.state.acceptedTerms || (this.state.password !== this.state.confirmpassword) || this.state.password === "" ||
                              this.state.contactNumber === "" || this.state.username === "" || this.state.lname === "" || this.state.fname === "");
    return (
      <>
        <Header />
        <CustomNavbar />
        <section className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
          <Container>
            <Row className="justify-content-md-center align-items-center">
              <div className="block-content">
                <div className="block-title">
                  <h3>Join Sharearide for Free</h3>
                  <h5>Access various bus operator services, a wide range of buses and journey to your place of interest</h5>
                </div>
                <div className="content">
                  <div >
                  { this.state.processing ? 
                    <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                      <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                        <Spinner animation="grow" variant='success' />
                      </div>
                    </div>:
                    <form id="form-register" className="rounded">
                      <div className="form-content">
                        <Row className="align-items-left" >
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerFirstname">First Name<span style={{color: "red"}}>&nbsp;*</span></label>
                              <input type="text" name="fName" id="registerFirstname" className="form-control" placeholder="" value={this.state.fname} onChange={this.handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerLastname">Last Name<span style={{color: "red"}}>&nbsp;*</span></label>
                              <input type="text" name="lName" id="registerLastname" className="form-control" placeholder="" value={this.state.lname} onChange={this.handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerUsername">Username<span style={{color: "red"}}>&nbsp;*</span></label>
                              <input type="text" name="userName" id="registerUsername" className="form-control" placeholder="" value={this.state.username} onChange={this.handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerContactNumber">Contact Number<span style={{color: "red"}}>&nbsp;*</span></label>
                              <PhoneInput enableAreaCodes={true} name="cellphone" id="registerContactNumber" 
                                inputStyle={{
                                  width: this.state.width,
                                  height:'45px'
                                }} country={'bw'}
                                value={this.state.contactNumber} onChange={this.handlePhoneChange} style={{width: "80%"}}
                                isValid={(value, country) => {
                                  const phoneUtil = PhoneNumberUtil.getInstance();
                                  try {
                                    if (value.length > 7 && !phoneUtil.isValidNumber(phoneUtil.parse("+" + value ))) {
                                      return 'Invalid value: ' + value + ', ' + country.name;
                                    } else {
                                      return true;
                                    }
                                  } catch(e) {
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerEmail">Email Address<span>&nbsp;(Optional)</span></label>
                              <input type="email" name="registerEmail" id="registerEmail" className="form-control" placeholder="" value={this.state.email} onChange={this.handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerPassword">Password<span style={{color: "red"}}>&nbsp;*</span></label>
                              <input type="password" name="registerPassword" id="registerPassword" className="form-control" placeholder="" value={this.state.password} onChange={this.handleChange} />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="form-group">
                              <label htmlFor="registerPasswordConfirm">Confirm Password<span style={{color: "red"}}>&nbsp;*</span></label>
                              <input type="password" name="registerPasswordConfirm" id="registerPasswordConfirm" className="form-control" placeholder="" value={this.state.confirmpassword} onChange={this.handleChange} />
                            </div>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center align-items-left" style={{ marginTop:10.5}}>
                          <Col md={9} xs={8}>
                            <label className="label-container checkbox-default" style={{ marginTop:10.5}}>
                              <span > I accept {" "} <a href="/terms_conditions">terms of use</a> and{" "} <a href="/privacy_policy">privacy policy</a> </span>
                              <input type="checkbox" onClick={this.acceptTerms} />
                              <span className="checkmark"></span>
                            </label>
                          </Col>
                          <Col md={3} xs={4}>
                            <input id="sign-up-button" type="submit" className="form-control rounded" value="SignUp" onClick={this.handleclick} 
                              disabled={disabled}
                              style={{
                                backgroundColor: disabled && 'gray', cursor: disabled && 'no-drop'
                              }}/>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  }
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) =>{
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
      signupSuccess: (user) =>{dispatch(signupSuccess(user))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
