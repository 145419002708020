import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col, 
  Form,
  FormGroup,
  Input,
} from "reactstrap";

function Footer() { 
  return (
    <footer id="footer" className="footer-dark">
      <div id="footer-bar-1" className="footer-bar text-white">
        <div className="footer-bar-wrap">
          <Container>
            <Row>
              <Col md={12}>
                <div className="fb-row">
                  <Row>
                    <Col md={6} lg={3}>
                    <h4>About Sharearide</h4>
                      <div className="footer-menue">
                          <div className="footer-col-1">  
                            <Link to="/about">About Us</Link>
                            <Link to="/blog">Blog</Link>
                            <Link to="/contact_us">Contact Us</Link>
                            <Link to="/blog">Careers</Link>
                          </div>
                      </div>
                    </Col>
                    <Col md={6} lg={3} className="mt-md-50">
                      <h4>Info</h4>
                      <div className="footer-menue">
                          <div className="footer-col-1">
                            <Link to="/privacy_policy">Privacy Policy</Link> 
                            <Link to="/terms_conditions">T&C</Link>
                            <Link to="/refund_policy">Refund Policy</Link>
                            <Link to="/faqs">FAQs</Link>
                          </div>
                          
                      </div>
                    </Col>
                    <Col md={6} lg={3} className="mt-md-50">
                      <h4>Contact Us</h4>
                      <ul className="list-contact-info">
                        <li>
                          <i className="fas fa-map-marker-alt"></i>
                          <div className="info-content">
                            <span>
                              Gaborone, Botswana Plot 69184, Block 8, Botswana Innovation Hub Science and Technology Park
                            </span>
                          </div>
                        </li>
                        <li>
                          <i className="fas fa-phone-alt"></i>
                          <div className="info-content">
                            <span>+267 75703276</span>
                          </div>
                        </li>
                        <li>
                          <i className="fas fa-envelope"></i>
                          <div className="info-content">
                            <span>info@sharearide.co.bw</span>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={6} lg={3} className="mt-md-50">
                      <h4>Stay In Touch</h4>
                      {/* <Form className="form-newsletter-register form-inline form-h-50">
                        <FormGroup>
                          <div className="box-field">
                            <Input type="text" name="email" className="form-control" placeholder="Enter Your Email Address"/>
                            <Button type="submit" className="form-control icon">
                              <i className="fas fa-long-arrow-alt-up"></i>
                            </Button>
                          </div>
                        </FormGroup>
                        <span className="form-note">
                          Enter your email address for promotions, news and
                          updates.
                        </span>
                      </Form> */}
                      <img className="mb-10 logo-footer" src={require("../../assets/images/files/share-a-ride-logo-white.png")} alt=""/>
                      <p style={{fontSize: '16px'}}>
                        Sharearide is a mobile and web application designed to support growing public transportation concerns  by offering fully managed technology solutions at an affordable cost.
                      </p>
                      <ul className="social-icons x5 white hover-colorful icon-only mt-10">                        
                        <li>
                          <a className="si-twitter" href="https://twitter.com/sharearidebw">
                            <i className="fab fa-twitter"></i>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a className="si-facebook" href="https://www.facebook.com/sharearidebw">
                            <i className="fab fa-facebook"></i>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a className="si-instagramorange" href="https://www.linkedin.com/company/sharearidebw/">
                            <i className="fab fa-linkedin"></i>
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div id="footer-bar-2" className="footer-bar text-white">
        <div className="footer-bar-wrap">
          <Container>
            <Row>
              <Col md={12}>
                <div className="fb-row">
                  <div className="copyrights-message">
                    <span>All right reserved to</span>{" "}
                    <Link to="/">
                       sharearide
                    </Link>{" "}
                    <span>©2020</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
