import React from "react";
import {
  Container,
  Row,
} from "reactstrap";
import axios from "axios";
import { connect } from 'react-redux';
import { emailActivationSucces } from '../actions/userActions';
import alertify from "alertifyjs";
import { ENDPOINT } from "../constants";
import { persister } from "../redux/store";

class EmailActivation extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      email_code: '',
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0);
  }

  handleChange = (e) =>{
    if(e.target.id === 'emailCode'){
      this.setState({email_code: e.target.value});
    }
  }

  handleClick = async (e) =>{
    e.preventDefault();

    var email_code_el = document.getElementById('emailCode');

    email_code_el.style.border = "1px solid #ddd";

    if(!/^[0-9]+$/.test(this.state.email_code)){
        if(this.state.email_code === ""){
          alertify.notify('Code can not be empty', 'custom', 2, function(){});
          email_code_el.style.border = "1px solid #F98575";
          return;
        }else{
          alertify.notify('Code should only contain numbers', 'custom', 2, function(){});
          email_code_el.style.border = "1px solid #F98575";
          return;
        }
    }else{
      email_code_el.style.border = "1px solid #ddd";
    }
    
    const config = {
      dataType: "json",
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer '+this.props.Auth?.token
      }
    };
    await axios.post(ENDPOINT+'/email-activation',{user_id: this.props.user.id, email_code: this.state.email_code},config)
    .then(response => {
      const { data = [], success } = response?.data;
      if (success) {
        this.props.emailActivationSucces(data.user);
        alertify.notify(data.message, 'customsuccess', 2, function(){});
        this.props.history.push("/user/profile");
      }else{
        alertify.notify(data.message, 'custom', 2, function(){});
      }
    })
    .catch((error) => {
      alertify.notify(error.message, 'custom', 2, function(){});
      if(error.message === 'Request failed with status code 401'){
        persister.purge();
      }
    });
  }
  render() {
    return (
      <>
        <section style={{backgroundColor: '#f6f6f6'}} className="popup-preview-2 popup-preview-login login-section pb-90 pt-90">
          <Container>
            <Row className="justify-content-md-center align-items-center">
              <div className="block-content">
                <div className="block-title">
                  <h3>Email Activation</h3>
                  <h5>Please enter below email activation code to activate your account</h5>
                </div>
                <div className="content">
                  <div className="left">
                    <form id="form-login" className="rounded">
                      <div className="form-content">
                        <div className="form-group">
                          <label htmlFor="loginUserEmail">
                            Code
                          </label>
                          <input
                            type="password"
                            name="jaName"
                            id="emailCode"
                            className="form-control"
                            value={this.state.email_code}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="submit"
                            className="form-control rounded"
                            value="Activate Account"
                            onClick={this.handleClick}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) =>{
  return {
    is_logged_in: state.Auth?.isAuthenticated,
    token: state.Auth?.token,
    user: state.Auth?.user
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
      emailActivationSucces: (data) =>{
        dispatch(emailActivationSucces(data))
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailActivation);
