import React from "react";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import axios from "axios";
import { ENDPOINT } from "../constants";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import domtoimage from 'dom-to-image';
import { default as JSPDF } from 'jspdf';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/react";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class Ticket extends React.Component {
    constructor(props){
        super(props);        
        this.state = {
            tickets: [],
            loading: true,
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + this.props?.Auth?.token,
            },
        };
        await axios.get(`${ENDPOINT}/app/v2/ticket-details/${this.props?.Auth?.user?.userId}/user-details`, config).then(res=>{
            const { data, success } = res?.data;
            if(success){
                this.setState({
                    tickets: data
                });
            }
        });         
        
        this.setState({
            loading: false,
        });
    }

    renderTicket(ticketDetails){
        const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const d = new Date(ticketDetails?.booking_date);
        var mon = d.getMonth();
        mon = month[mon];
        const date = d.getDate();
        const qrCodeData = {
            usernames: `${ticketDetails?.fname} ${ticketDetails?.lname}` ,
            reservationNumber: ticketDetails?.reservation_number,
            date: ticketDetails?.booking_Date,
            departure: ticketDetails?.departure, 
            destination: ticketDetails?.arrival, 
            departureTime: ticketDetails?.departureTime, 
            busOperator: ticketDetails?.partner_id, 
            busRegistrationNumber: ticketDetails?.reg_no, 
            seats: ticketDetails?.seats, 
            ticketPrice: ticketDetails?.fare, 
            totalPrice: ticketDetails?.ticket_Price
        };

        return <div key={ticketDetails?.uuid}>
            <div style={{width: '300px', margin: '0 auto'}}>
                <img src={require('../assets/images/files/ticket-top.png')} />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="ticket-outer" id={"ticket" + ticketDetails?.uuid} style={{width: '300px'}}>
                    <div className="ticket-inner">
                        <div className="ticket-heading">ticket # { ticketDetails?.reservation_number }</div>
                        <div className="ticket-description-1">
                            <div className="ticket-description-1-heading" style={{display: 'inline-block'}}>{ticketDetails?.name}</div>
                            <div className="ticket-description-1-date">{ mon + ' ' + date }, { ticketDetails?.departure }</div>
                        </div>
                        <div className="ticket-description-2 mt-5">
                            <div className="ticket-description-2-left">
                                <div className="bus-service">Bus Service</div>
                                <div className="station"><b> Sharearide </b></div>
                            </div>
                            <div className="ticket-description-2-right">
                                <div className="bus-service">Booking  Seats</div>
                                <div className="station station-2">{ticketDetails?.seats?.replace(/'/g, '').split(',')}</div>
                            </div>                                    
                        </div>
                        <div className="ticket-description-2 mt-5 mb-5">
                            <div className="ticket-description-2-left">
                                <div className="bus-service">Boarding</div>
                                <div className="station"><b>  {ticketDetails?.departureTime}</b></div>
                                <div className="station time">({ticketDetails?.departure})</div>
                            </div>
                            <div className="ticket-description-2-right">
                                <div className="bus-service">Drop</div>
                                <div className="station"><b>  {ticketDetails?.arrivalTime}</b></div>
                                <div className="station time">({ticketDetails?.arrival})</div>
                            </div>                                    
                        </div>
                        <div className="ticket-description-2 mt-5 mb-3">
                            <QRCode value={JSON.stringify(qrCodeData)} />
                        </div>                                
                    </div>                                
                </div>
            </div>
            <div style={{width: '300px', margin: '0 auto'}}>
                <img src={require('../assets/images/files/ticket-bottom.png')} />
            </div>
            <div style={{width: '300px', margin: '0 auto'}}>
                <Button id={'ticket' + ticketDetails?.uuid +'-btn'} style={{marginTop: '20px', paddingTop: '10px', paddingBottom: '10px', border: 'none'}} onClick={async ()=>{
                    let el = document.getElementById('ticket' + ticketDetails?.uuid);
                    let img_src;
                    await domtoimage.toPng(el).then( function (dataUrl) {
                        let img = new Image();
                        img.src = dataUrl;
                        img_src = dataUrl;
                    });
                    const doc = new JSPDF();
                    doc.addImage(img_src, "PNG", 5, 5);
                    doc.save(`${ticketDetails?.uuid_}ticket.pdf`);
                }}>print</Button>
            </div>
        </div>
    }

    render(){       
        
        return (
            <>
                {this.state.processing ? 
                    <div style={{opacity: 0.8, backgroundColor:'#ccc', position: 'fixed', width:'100%', height:'100%', top:'0px', left:'0px', zIndex:1000}}>
                        <div style={{ position: 'absolute', left: '60%', top: '50%', transform: 'translate(-60%, -50%)' }} >
                            <Spinner animation="grow" variant='success' />
                        </div>
                    </div>:                   

                    <section className="page-single bg-grey">
                        <div id="content-wrap">
                            <div className="section-flat">
                                <div className="section-content">
                                    {!this.state.loading &&
                                        <Carousel responsive={responsive} >
                                            {this.state.tickets.map(ticket =>{
                                                return this.renderTicket(ticket)
                                            })}
                                        </Carousel>
                                    }
                                    <Container>
                                        <Row>
                                            <Col lg={12} md={12} >
                                            {this.state.loading && <RingLoader color="#549a8b" loading={this.state.loading} css={override} size={150} />}
                                            {(!this.state.loading && this.state.tickets?.length === 0) &&
                                                <div className="block-content-2 style-3">
                                                    <div>
                                                        <img style={{width: '78px'}} src={require('../assets/images/files/bus-stop.svg')} />
                                                        <p style={{fontSize: '30px', color: '#212a42', fontWeight: '600'}}>No Bookings Found</p>
                                                    </div>
                                                </div>
                                            }
                                            </Col>                                       
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        ...state
    }
}

export default connect(mapStateToProps, null)(Ticket);